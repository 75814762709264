<template>
	<section>
		<b-card-countdown
			v-if="competitionData"
			stage="evaluación de propuestas"
			:deadline="deadline"
			:start-date="startDate"
			:stage-closed="stageClosed"
			@time-finished="handleTimeFinished"
			@not-yet-started="handleNotYetStarted"
		/>

		<b-row>
			<b-col
				cols="12"
				v-if="bidderName"
			>
				<statistic-card-horizontal
					icon="UserIcon"
					color="info"
					:statistic="bidderName"
					statistic-title="Postor"
				/>
			</b-col>

			<b-col
				cols="12"
				v-if="competitionData"
			>
				<b-card>
					<b-tabs
						content-class="mt-2"
						justified
					>
						<b-tab
							active
							title="Propuesta Económica"
						>
							<tab-proposal-economic
								:competition-review-id="competitionData.id"
								:competition-id="competitionData.competition.id"
								:economic-proposal-tender="competitionData.competition.tender.request.request_economic_template"
								:economic-proposal="competitionData.competition_economic_review.economic_proposal"
								:is-disabled="isDisabled || competitionData.status"
							/>
							<!-- :economic-review-id="competitionData.competition_economic_review.id" -->
						</b-tab>

						<b-tab title="Propuesta de Seguridad, Calidad, Medio Ambiente y Anti Corrupción">
							<tab-proposal-sig
								:competition-review-id="competitionData.id"
								:competition-id="competitionData.competition.id"
								:tender-id="competitionData.competition.tender.id"
								:proposals-data="competitionData.competition_sig_technical_reviews"
								:is-disabled="isDisabled || competitionData.status"
								:is-assigned="competitionData.is_assigned"
							/>
						</b-tab>

						<b-tab title="Propuesta Técnica">
							<tab-proposal-technical
								:competition-review-id="competitionData.id"
								:competition-id="competitionData.competition.id"
								:tender-id="competitionData.competition.tender.id"
								:proposals-data="competitionData.competition_sig_technical_reviews"
								:is-disabled="isDisabled || competitionData.status"
								:is-assigned="competitionData.is_assigned"
							/>
						</b-tab>
					</b-tabs>
				</b-card>
			</b-col>

			<b-col
				cols="12"
				v-if="competitionData"
			>
				<b-button-loading
					v-if="!competitionData.status || !competitionData.is_assigned"
					text="FINALIZAR REVISIÓN"
					size="lg"
					variant="relief-primary"
					:block="true"
					@process-action="sendInformation"
					:processing="processing"
					:disabled="isDisabled || competitionData.status"
				/>
			</b-col>
		</b-row>
	</section>
</template>

<script>
import {
	ref,
	onMounted,
	onUnmounted,
	onBeforeMount
} from "@vue/composition-api"
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue"
import { Stages, TenderStates } from "@core/utils/data"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import TabProposalEconomic from "./tab-economic/TabEconomic.vue"
import TabProposalSig from "./tab-sig/TabSig.vue"
import TabProposalTechnical from "./tab-technical/TabTechnical.vue"
import proposalStoreModule from "./proposalStoreModule"
import useNotifications from "@notifications"
import router from "@/router"
import axios from "@axios"
import VueSweetalert2 from "vue-sweetalert2"
import Vue from "vue"
import store from "@/store"
import moment from "moment"

Vue.use(VueSweetalert2)

export default {
	components: {
		BCardCountdown,
		BButtonLoading,
		TabProposalEconomic,
		TabProposalSig,
		TabProposalTechnical,
		StatisticCardHorizontal
	},
	setup(props) {
		const { swalNotification, toastNotification } = useNotifications()

		const { FINALIZADO, EN_REVISION } = TenderStates

		const tenderId = ref(router.currentRoute.params.id)
		const competitionReviewId = ref(
			router.currentRoute.params.competition_review_id
		)
		const deadline = ref(null)
		const startDate = ref(null)
		const processing = ref(false)
		const stageClosed = ref(false)
		const isDisabled = ref(false)
		const bidderName = ref("")
		const timeLeft = ref({
			tenMinutes: false,
			fiveMinutes: false,
			threeMinute: false
		})

		const competitionData = ref(null)

		const handleTimeFinished = () => {
			stageClosed.value = true
			isDisabled.value = true
		}
		const handleNotYetStarted = (value) => {
			isDisabled.value = value
			stageClosed.value = value
		}

		const PROPOSAL_APP_STORE_MODULE_NAME = "app-review"

		// REGISTER MODULE
		if (!store.hasModule(PROPOSAL_APP_STORE_MODULE_NAME))
			store.registerModule(PROPOSAL_APP_STORE_MODULE_NAME, proposalStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(PROPOSAL_APP_STORE_MODULE_NAME))
				store.unregisterModule(PROPOSAL_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			store
				.dispatch("app-review/fetchCompetitionReview", {
					id: competitionReviewId.value
				})
				.then((response) => {
					competitionData.value = response.data.competitionReview
					setData(competitionData.value)
				})
				.catch((error) => {
					console.log(error.response.data.message)
					competitionData.value = undefined
					toastNotification(
						error.message ||
							error.response?.data?.message ||
							"Error al obtener las propuestas del postor",
						false
					)
				})
		}

		const setData = (data) => {
			bidderName.value = data.bidder_name
			if (data.competition.tender.tender_state_id !== EN_REVISION) {
				stageClosed.value = true
				isDisabled.value = true
			}

			const evaluation = data.competition.tender.tender_schedules.find(
				(item) => item.tender_stage_id === Stages.EVALUACION_PROPUESTA
			)
			deadline.value = evaluation.actual_end_date || ""
			startDate.value = evaluation.actual_start_date || ""

			const currentDate = moment()
			const inputDate = moment(deadline.value)

			if (currentDate.isAfter(inputDate)) {
				stageClosed.value = true
				isDisabled.value = true
			}
		}

		onBeforeMount(() => {
			getData()
		})

		const sendInformation = () => {
			processing.value = true

			Vue.swal({
				title: "¿Está seguro(a) de finalizar la evaluación?",

				html: "<small>¡Recuerda <strong>guardar tus cambios</strong> antes de enviar!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, continuar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then(async (result) => {
				if (result.value) {
					try {
						const response = await axios.put(
							`/competition-reviews/${competitionReviewId.value}`,
							{ tender_id: tenderId.value, final_revision: true }
						)
						swalNotification(response.data.message)
						router.push({
							name: "tenders-edit",
							params: { id: tenderId.value },
							query: { tab: "3" }
						})
					} catch (error) {
						const message =
							error.response.data.message || "Error al finalizar evaluación"
						swalNotification(message, false)
					} finally {
						processing.value = false
					}
				} else {
					processing.value = false
				}
			})
		}

		return {
			competitionData,
			processing,
			timeLeft,
			isDisabled,
			stageClosed,
			deadline,
			startDate,
			bidderName,

			sendInformation,
			handleTimeFinished,
			handleNotYetStarted,

			moment
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>