<template>
	<section>
		<validation-observer
			#default="{ handleSubmit }"
			ref="refEconomicForm"
		>
			<b-form @submit.prevent="handleSubmit(saveInformation)">
				<b-card
					footer-tag="footer"
					class="mb-0"
				>
					<b-row>
						<b-col cols="12">
							<h5 class="mb-1">
								<strong>Presentación de Propuesta Económica</strong>
							</h5>

							<div v-html="economicProposalTender.description"></div>
						</b-col>

						<b-col
							cols="12"
							class="d-flex justify-content-end"
						>
							<b-button
								v-ripple.400="'rgba(113, 102, 240, 0.15)'"
								variant="outline-primary"
								class="btn-icon rounded-circle mr-1"
								@click="openCommentModal()"
							>
								<feather-icon icon="MessageSquareIcon" />
							</b-button>
							<b-button-upload
								entity="COMPETITIONS"
								:entity-id="competitionId"
								:is-disabled="true"
								:competition-props="{ isEconomic: true, ownerId: economicProposal.id }"
								:modal-props="{ title: 'DOCUMENTOS DE PROPUESTA ECONOMICA' }"
								:button-props="{ onlyIcon: true }"
							/>
						</b-col>

						<b-col
							cols="12"
							class="my-2"
						>
							<hr />
						</b-col>

						<b-col
							cols="4"
							class="mb-1"
						>
							<label for="tipo">
								<strong>TIPO DE MONEDA</strong>
							</label>
							<b-form-input
								id="tipo"
								placeholder="Soles"
								:value="economicProposalTender.currency_name"
								disabled
								readonly
							/>
						</b-col>
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="general_expenses">
								<strong>GASTO GENERAL (GG %)</strong>
							</label>
							<b-form-input
								id="general_expenses"
								:value="economicForm.general_expenses"
								type="number"
								disabled
								readonly
							/>
						</b-col>
						<b-col
							cols="12"
							md="4"
							class="mb-1"
						>
							<label for="profit">
								<strong>UTILIDAD (UTI %)</strong>
							</label>
							<b-form-input
								id="profit"
								:value="economicForm.profit"
								type="number"
								disabled
								readonly
							/>
						</b-col>

						<b-col
							cols="12"
							v-if="economicForm.file_url"
						>
							<b-icon-file
								:filename="economicForm.file_original_name"
								:file-url="economicForm.file_url"
							/>
						</b-col>

						<b-col cols="12">
							<b-card
								no-body
								class="card-statistics mb-0"
							>
								<b-card-body class="statistics-body">
									<b-row>
										<b-col
											v-for="item in statisticsItems"
											:key="item.icon"
											md="3"
											sm="6"
											class="mb-2 mb-md-0"
											:class="item.customClass"
										>
											<b-media no-body>
												<b-media-aside class="mr-2">
													<b-avatar
														size="48"
														:variant="item.color"
													>{{ item.sign }}</b-avatar>
												</b-media-aside>
												<b-media-body class="my-auto">
													<h4 class="font-weight-bolder mb-0">{{ formatCurrency(item.amount) }}</h4>
													<b-card-text class="font-small-3 mb-0">{{ item.subtitle }}</b-card-text>
												</b-media-body>
											</b-media>
										</b-col>
									</b-row>
								</b-card-body>
							</b-card>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>

		<b-modal
			ref="refCommentModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			centered
			title="PRESENTACIÓN DE PROPUESTA ECONÓMICA"
			size="lg"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
		>
			<b-form>
				<b-col
					cols="12"
					class="my-2"
				>
					<label for="comment">
						<strong>Comentario</strong>
					</label>
					<b-form-group>
						<b-form-textarea
							id="comment"
							:value="comment"
							rows="4"
							disabled
							readonly
						/>
					</b-form-group>
				</b-col>
			</b-form>

			<template #modal-footer="{ cancel }">
				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</section>
</template>

<script>
import {
	ref,
	onBeforeMount,
	onMounted,
	onUnmounted
} from "@vue/composition-api"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { required, min } from "@validations"
import BCardCountdown from "@core/components/b-card-countdown/BCardCountdown"
import BButtonUpload from "@core/components/b-button-upload/BButtonUpload.vue"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import BIconFile from "@core/components/b-icon-file/BIconFile.vue"
import Ripple from "vue-ripple-directive"
import { VBModal } from "bootstrap-vue"
import { formatCurrency } from "@core/utils/filter"
import axios from "@axios"
import useNotifications from "@notifications"
import tabEconomicStoreModule from "./tabEconomicStoreModule"
import store from "@/store"

export default {
	components: {
		ValidationProvider,
		ValidationObserver,

		BCardCountdown,
		BButtonLoading,
		BButtonUpload,
		BIconFile,

		required,
		min
	},
	directives: {
		"b-modal": VBModal,
		Ripple
	},
	props: {
		competitionReviewId: {
			type: Number,
			required: false
		},
		competitionId: {
			type: Number,
			required: false
		},
		economicId: {
			type: Number,
			required: false
		},
		economicProposal: {
			type: Object,
			default: () => {},
			required: true
		},
		economicProposalTender: {
			type: Object,
			default: () => {},
			required: true
		},
		isDisabled: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	setup(props) {
		const COMPETITION_ECONOMIC_APP_STORE_MODULE_NAME =
			"app-competition-economic"

		// REGISTER MODULE
		if (!store.hasModule(COMPETITION_ECONOMIC_APP_STORE_MODULE_NAME))
			store.registerModule(
				COMPETITION_ECONOMIC_APP_STORE_MODULE_NAME,
				tabEconomicStoreModule
			)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(COMPETITION_ECONOMIC_APP_STORE_MODULE_NAME))
				store.unregisterModule(COMPETITION_ECONOMIC_APP_STORE_MODULE_NAME)
		})

		const getData = () => {
			/* store
				.dispatch("app-competition-economic/fetchCompetitionEconomicReview", {
					id: props.economicId
				})
				.then((response) => {
					setData(response.data.economicData)
				})
				.catch((error) => {
					console.log("1", error)
					const message = error.message || error.response?.data?.message
					toastNotification(message, false)
				}) */
		}

		const economicProposal = ref(props.economicProposal)

		const file = ref(null)
		const refCommentModal = ref(null)
		const comment = ref(null)
		const processing = ref(false)

		const economicForm = ref({
			comment: null,
			subtotal: 0,
			general_expenses: 0,
			profit: 0,
			file_url: null,
			file_original_name: null
		})
		const statisticsItems = ref([
			{
				color: "light-primary",
				sign: "S/",
				amount: 0,
				subtitle: "COSTO DIRECTO",
				customClass: "mb-2 mb-xl-0"
			},
			{
				color: "light-info",
				amount: 0,
				sign: "S/",
				subtitle: "GASTO GENERAL",
				customClass: "mb-2 mb-xl-0"
			},
			{
				sign: "S/",
				color: "light-danger",
				amount: 0,
				subtitle: "UTILIDAD",
				customClass: "mb-2 mb-sm-0"
			},
			{
				sign: "S/",
				color: "light-success",
				amount: 0,
				subtitle: "IMPORTE TOTAL",
				customClass: ""
			}
		])

		const openCommentModal = () => {
			comment.value = economicForm.value.comment
			refCommentModal.value.show()
		}

		onMounted(() => {
			setData()
		})

		const setData = () => {
			const {
				general_expenses,
				profit,
				subtotal,
				file_url,
				file_original_name,
				comment
			} = economicProposal.value

			economicForm.value = {
				comment,
				general_expenses: general_expenses
					? (parseFloat(general_expenses) * 100).toFixed(2)
					: 0,
				profit: profit ? (parseFloat(profit) * 100).toFixed(2) : 0,
				subtotal: subtotal ? parseFloat(subtotal) : 0,
				file_url,
				file_original_name
			}

			calculateStatistics()
		}

		const calculateStatistics = () => {
			statisticsItems.value[0].amount = economicForm.value.subtotal
			statisticsItems.value[1].amount =
				economicForm.value.subtotal *
				(economicForm.value.general_expenses / 100)
			statisticsItems.value[2].amount =
				economicForm.value.subtotal * (economicForm.value.profit / 100)

			statisticsItems.value[3].amount =
				statisticsItems.value[0].amount +
				statisticsItems.value[1].amount +
				statisticsItems.value[2].amount
		}

		return {
			refCommentModal,
			comment,
			file,
			statisticsItems,
			economicForm,
			processing,
			formatCurrency,

			openCommentModal
		}
	}
}
</script>