import axios from '@axios';

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchCompetitionEconomicReview(ctx, { id }) {
			return new Promise((resolve, reject) => {
				axios
					.get(`/competition-economic-reviews/${id}/edit`)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		},
		updateCompetitionEconomicReview(ctx, { id, payload }) {
			return new Promise((resolve, reject) => {
				axios
					.put(`/competition-economic-reviews/${id}`, payload)
					.then(response => resolve(response))
					.catch(error => reject(error));
			});
		}
	}
}